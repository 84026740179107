<template>
  <div>
    <div class="title">
      <div class="title_t">
        <span>客户详情</span>
      </div>
      <div class="title_r" @click="returnPage">
        <span>返回</span>
      </div>
    </div>

    <!--选项-->
    <div class="xuanxiang">
      <div class="xuanxiang_k" v-for="(item,index) in activeList" :key="index" :class="{xuanxiang_k1:index==isShow}" @click="xuanxiangclick(item,index)">
        <!--<img :src="item.img" alt="">-->
        <span>{{item.name}}</span>
      </div>
    </div>

    <!--计价公式 模块-->
    <div class="Pricingformula" v-if='isShow == 0'>
      <div>
        <Pricingformula :id="id" :data="data" :dict="dict"></Pricingformula>
      </div>
    </div>
    <!--通讯录 模块-->
    <div class="Addressbook" v-if='isShow == 1'>
      <div>
        <Addressbook :id="id" :data="data" :dict="dict"></Addressbook>
      </div>
    </div>


    <!--服务配置 模块-->
    <!--<div class="Addressbook" v-if='isShow == 2'>-->
    <!--  <div>-->
    <!--    <ServiceConfiguration :id="id" :data="data" :dict="dict"></ServiceConfiguration>-->
    <!--  </div>-->
    <!--</div>-->

    <!--合同信息 模块-->
    <div class="Addressbook" v-if='isShow == 2'>
      <div>
        <ContractInfo :id="id" :data="data" :dict="dict"></ContractInfo>
      </div>
    </div>

    <!--话术配置 模块-->
    <div class="Addressbook" v-if='isShow == 3'>
      <div>
        <Scriptconfiguration :id="id" :data="data" :dict="dict" @showDetalis="ShowDetalis"></Scriptconfiguration>
      </div>
    </div>


  </div>
</template>

<script>
// 引入计价公式组件
import Pricingformula from './components/Pricingformula.vue'
// 引入通讯录组件
import Addressbook from './components/Addressbook.vue'
// 引入服务配置组件
import ServiceConfiguration from './components/ServiceConfiguration.vue'
// 引入合同信息组件
import ContractInfo from './components/ContractInfo.vue'
// 引入话术配置组件
import Scriptconfiguration from './components/Scriptconfiguration.vue'

// 引入的接口
import {Addclient, channelinfo, Editclient, parent_select, son_select} from "@/api/custom";
import Vue from "vue";

// 获取配置
import setting from '@/config/setting'
import {getCookies, setCookies} from '@/utils/cookies'
import Config from '@/config'

export default {
  name: 'index',
  // 支柱道具属性
  props: {
    // 修改回显的数据
    data: Object,
    // 父级id
    id: Number,
    //字典数据
    dict:Object,
  },
  // 组件生效
  components: {
    Pricingformula,
    Addressbook,
    ServiceConfiguration,
    ContractInfo,
    Scriptconfiguration
  },

  // Data数据
  data() {
    return {
      isShow:0,
      activeList:[
        {
          // img: require('../../../assets/images/home/custom/icon2active.png'),
          name:'计价公式',
        },
        {
          // img: require('../../../assets/images/home/custom/icon3.png'),
          name:'通讯录',
        },
        // {
        //   img: require('../../../assets/images/home/custom/icon4.png'),
        //   name:'服务配置',
        // },
        // {
        //   img: require('../../../assets/images/home/custom/icon5.png'),
        //   name:'合同信息',
        // },
        // {
        //   img: require('../../../assets/images/home/custom/icon6.png'),
        //   name:'话术配置',
        // }
      ],


      // 上传接口
      action: setting.apiBaseURL + 'common/upload/client',
      // 上传请求头部
      headers:{
        'Authori-Zation' : 'Bearer' + ' ' + getCookies(Config.tokenStoreName)
      },

    }
  },
  // 计算属性 类似于 data 概念
  computed: {},
  // 监控 data 中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this 实例）
  created() {
  },

  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {

  },

  // 方法集合
  methods: {
    // 选项卡点击事件
    xuanxiangclick(item,index){
      console.log(item);
      console.log(index);
      this.isShow = index;
      // if(item.name == '计价公式'){
      //   this.activeList[0].img = require('../../../assets/images/home/custom/icon2active.png');
      //   this.activeList[1].img = require('../../../assets/images/home/custom/icon3.png');
      //   // this.activeList[2].img = require('../../../assets/images/home/custom/icon4.png');
      //   this.activeList[2].img = require('../../../assets/images/home/custom/icon5.png');
      //   this.activeList[3].img = require('../../../assets/images/home/custom/icon6.png');
      // }else if(item.name == '通讯录'){
      //   this.activeList[0].img = require('../../../assets/images/home/custom/icon2.png');
      //   this.activeList[1].img = require('../../../assets/images/home/custom/icon3active.png');
      //   // this.activeList[2].img = require('../../../assets/images/home/custom/icon4.png');
      //   this.activeList[2].img = require('../../../assets/images/home/custom/icon5.png');
      //   this.activeList[3].img = require('../../../assets/images/home/custom/icon6.png');
      // // }
      // // else if(item.name == '服务配置'){
      // //   this.activeList[0].img = require('../../../assets/images/home/custom/icon2.png');
      // //   this.activeList[1].img = require('../../../assets/images/home/custom/icon3.png');
      // //   this.activeList[2].img = require('../../../assets/images/home/custom/icon4active.png');
      // //   this.activeList[3].img = require('../../../assets/images/home/custom/icon5.png');
      // //   this.activeList[4].img = require('../../../assets/images/home/custom/icon6.png');
      // }else if(item.name == '合同信息'){
      //   this.activeList[0].img = require('../../../assets/images/home/custom/icon2.png');
      //   this.activeList[1].img = require('../../../assets/images/home/custom/icon3.png');
      //   // this.activeList[2].img = require('../../../assets/images/home/custom/icon4.png');
      //   this.activeList[2].img = require('../../../assets/images/home/custom/icon5active.png');
      //   this.activeList[3].img = require('../../../assets/images/home/custom/icon6.png');
      // }else if (item.name == '话术配置'){
      //   this.activeList[0].img = require('../../../assets/images/home/custom/icon2.png');
      //   this.activeList[1].img = require('../../../assets/images/home/custom/icon3.png');
      //   // this.activeList[2].img = require('../../../assets/images/home/custom/icon4.png');
      //   this.activeList[2].img = require('../../../assets/images/home/custom/icon5.png');
      //   this.activeList[3].img = require('../../../assets/images/home/custom/icon6active.png');
      // }
    },

    // 返回上级页面
    returnPage(){
      this.$router.push({query:{}})
      this.$emit('showDetalis',false) ;
    },

    // 接收话术配置子组件传递的值
    ShowDetalis(value){
      this.$emit('showDetalis',value) ;
    },

  },
  // 生命周期 - 更新之前
  beforeUpdate() {
  },
  // 生命周期 - 更新之后
  updated() {
  },
  // 过滤器
  filters: {},
  // 生命周期 - 创建之前
  beforeCreate() {
  },
  // 生命周期 - 挂载之前
  beforeMount() {
  },
  // 生命周期 - 销毁之前
  beforeDestroy() {
  },
  // 生命周期 - 销毁完成
  destroyed() {
  },
  // 如果页面有 keep-alive 缓存功能,这个函数会触发
  // 进入的时候触发
  activated() {
  },
  // 离开的时候触发
  deactivated() {
  }
}
</script>

<style scoped lang="scss">
.card{
  background: #ffffff;
  border-radius: 10px;
  padding: 20px;
  .card_t{
    display: flex;
    align-items: center;
  }
  .card_t_icon{
    width: 5px;
    height: 19px;
    background: #ff9b05;
  }
  .card_t_text{
    font-size: 20px;
    font-weight: 700;
    color: #202033;
    margin-left: 15px;
  }
}
.card_bottom{
  margin-top: 80px;
  text-align: center;
}

.condition{
  .condition_t{
    display: flex;
    align-items: center;
  }
  .condition_t_icon{
    width: 5px;
    height: 19px;
    background: #ff9b05;
  }
  .condition_t_text{
    font-size: 20px;
    font-weight: 700;
    color: #202033;
    margin-left: 15px;
  }

  .condition_b{
    margin-top: 20px;
    .condition_b_img{
      width: 280px;
      height: 255px;
      background-image: url("../../../assets/images/home/custom/upload_bg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      text-align: center;
      padding-top: 50px;
      cursor: pointer;
    }
    .condition_b_img_anniu{
      width: 94px;
      height: 94px;
    }
    .condition_b_img_text{
      font-size: 16px;
      font-weight: 500;
      color: #ff9b05;
    }
    .condition_b_l{
      width: 20%;
      float: left;
    }
    .condition_b_r{
      width: 76%;
      float: right;
      margin-left: 30px;
    }
  }
  // 解决高度坍塌
  .condition_b:after{
    content: '';
    clear: both;
    display: block;
  }
}

.xuanxiang{
  cursor: pointer;
  display: flex;
  .xuanxiang_k{
    width: 150px;
    height: 50px;
    background: #e8e8e8;
    border: 1px solid #dadada;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border-top-right-radius: 30px;
    img{
      width: 20px;
      height: 20px;
    }
    span{
      font-size: 20px;
      font-weight: 700;
      color: #7b7b7b;
      margin-left: 5px;
    }
  }
  .xuanxiang_k1{
    width: 150px;
    height: 50px;
    background: #ffffff;
    border: 1px solid #dadada;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border-top-right-radius: 30px;
    img{
      width: 20px;
      height: 20px;
    }
    span{
      font-size: 20px;
      font-weight: 700;
      color: #FF9B05;
      margin-left: 5px;
    }
  }
}

.hide_box /deep/ .el-upload--picture-card {
  display: none;
}
</style>
