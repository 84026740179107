<template>
  <!--计价公式 模块-->
  <div>
    <el-card shadow="never">
      <div class="where">
        <el-form
            :model="where"
            label-width="90px"
            class="ele-form-search"
            @submit.native.prevent>
          <el-row :gutter="15">
            <el-col :lg="21" :md="12">
              <!--占位-->
              <span style="display: inline-block;width: 10px;height: 10px;"></span>
            </el-col>
            <el-col :lg="3" :md="12">
              <div  class="ele-form-actions">
                <div class="custom" style="margin-left: 20px">
                  <el-button class="custom-button" @click="openEdit(null)">
                    <!--el-icon-devops 是自己命名的-->
                    <i class="el-icon-tianjia" />
                    <span style="vertical-align: middle">添加计价</span>
                  </el-button>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <!--数据表格-->
      <div class="dataList">
        <my-table
            ref="myTable"
            :columns="columns"
            :tableData="List"
            :cellStyle="cellStyle"
            :headerCellStyle="headerCellStyle"
            :rowClassName="rowClassName"
            :total="total"
            :customsFromWhere="where"
            :loading="loading"
            @select="select"
            @ChangeSize="ChangeSize"
            @currentChange="currentChange"
        >
          <!--自定义列显示 示例-->
          <template v-slot:status= "{ scope }">
            <div >
              <img style="cursor: pointer" src="@/assets/images/home/yiqiyong.png" alt="" v-if="scope.row.status == 0">
              <img style="cursor: pointer" src="@/assets/images/home/weiqiyong.png" alt="" v-else>
            </div>
          </template>

          <!-- 优先级 -->
          <template v-slot:priority = '{ scope }'>
            <div v-for="(item) in dict.type.valuation_priority">
              <span v-if="item.value == scope.row.priority">{{item.label}}</span>
            </div>
          </template>

          <!-- 汽车类型 -->
          <template v-slot:taximeter_type = '{ scope }'>
            <div v-for="(item) in dict.type.valuation_type">
              <span v-if="item.value == scope.row.taximeter_type">{{item.label}}</span>
            </div>
          </template>

          <!-- 计价标准 -->
          <template v-slot:config = '{ scope }'>
            <div v-for="(item,index) in scope.row.config" :key="index" style="text-align: left !important;">
              <div>{{item.details_name}}</div>
              <span style="margin: 5px;" v-for="(it,idx) in item.details" :key="idx">
                        <span style="margin: 5px;">{{it}}</span>
                    </span>
            </div>
          </template>

          <!-- 不使用数据可不接收scope值 -->
          <template v-slot:operationSlot= "{ scope }">
            <el-link :underline="false" type="warning" @click="openEdit(scope.row)">详情</el-link>
            <el-link :underline="false" type="warning" v-auths="[`${$config.uniquePrefix}custom:edit`]" @click="openEdit(scope.row)">编辑</el-link>
            <el-link :underline="false" @click="del(scope.row)">删除</el-link>
          </template>
        </my-table>
      </div>
    </el-card>

    <!-- 编辑弹窗 -->
    <pricingformula-edit v-if="showEdit === true" :id="id" :data="current" :dict="dict" :visible.sync="showEdit" @done="getList"/>

  </div>
</template>

<script>
// 引入编辑通讯录弹窗
import PricingformulaEdit from './components/pricingformula-edit.vue';
// 权限
import {ShowTable} from '@/utils'
// 配置
import Vue from "vue";
// 引入的接口
// 客户计价公式列表
import {qudaopricing_formula,qudaoDeltaximeter,channelget_config} from "@/api/custom";

export default {
  props:{
    id:Number
  },
  components:{
    PricingformulaEdit
  },
  data(){
    return{
      // 搜索条件
      where:{
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      },
      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading:false,
      // 表头数据
      columns: [
        {
          type: "selection",
          isShow: true
        },
        {
          label: "编号",
          // type: "index",
          // width: "70"
          prop: "id",
          isShow: true
        },
        {
          label: "公式名称",
          prop: "name",
          isShow: true
        },
        {
          label: "优先级",
          prop: "priority",
          slot: 'priority',
          isShow: true
        },
        {
          width: '250',
          label: "汽车类型",
          prop: "taximeter_type",
          slot: 'taximeter_type',
          isShow: true
        },
        {
          width: '600',
          label: "计价标准",
          prop: "config",
          slot: 'config',
          isShow: true
        },
        {
          label: "启用状态",
          prop: "status",
          slot: "status",
          // 权限判断
          auths: ShowTable([`${this.$config.uniquePrefix}custom:set_status`]),
          isShow: true
        },
        {
          label: "操作",
          width: "200",
          slot: "operationSlot",
          isShow: true
        },
      ],

      // 时效数据
      current: null,
      // 是否显示时效弹窗
      showEdit: false,

      //字典
      dict: {
        type:{}
      },

    }
  },

  mounted() {
    // 初始化dict
    this.$store.getters.dict.forEach(item => {
      if (item.name === '客户字典'){
        item.children.forEach(res => {
          Vue.set(this.dict.type, res.code, res.withChildren);
        })
      }
    });
    console.log(this.dict);

    this.getList();
  },

  methods:{
    // 点击添加计价按钮
    openEdit(row){
      //获取配置
      channelget_config(this.id).then(res => {
        if (res.code === 200) {
          this.current = row;
          this.showEdit = true;
        }else {
          this.$message.error(res.msg);
        }
      })
    },

    // 获取列表
    getList(){
      qudaopricing_formula(this.id,this.where).then(async (res) => {
        this.total = res.data.count;
        this.List = res.data.list;
        this.loading = false;
      }).catch((res) => {
        this.$message.error(res.msg);
        this.loading = false;
      })
    },


    // 当前页数改变事件
    currentChange(val) {
      console.log("当前页数", val)
      this.where.page = val;
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      console.log(selection)
      console.log("选择的所有数据行", selection)
      console.log("当前选择的数据行", row)
    },
    ChangeSize(val){
      console.log('当前页面显示的条数',val);
      this.where.limit = val;
      this.getList()
    },

    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name"
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #FAFAFA"
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name"
    },
    // 单元格的 style 的回调方法
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1 && columnIndex == 3) {
        // return {
        //   background: "skyBlue",
        //   "border-radius": "20px"
        // }
      }
    },

    // 删除
    del(row){
      console.log(row);
      let that = this;
      this.$utils.confirm("确定要删除吗？", function () {
        // 执行一些需要的逻辑
        qudaoDeltaximeter(that.id,row.id).then(res => {
          console.log(res)
          if(res.code === 200){
            that.$message.success(res.msg);
            that.getList();
          }else {
            that.$message.success(res.msg);
          }
        }).catch(e => {
          that.$message.error(e.msg)
        })
      }, "确定", 'warning');

    },


  }

}
</script>

<style lang="scss" scoped>
.content{

}
.dataList{
  margin-top: 10px;
}
</style>
